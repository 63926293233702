@import '../../../../../scss/theme-bootstrap';

.content-block--overlay {
  position: static;
  [role='dialog'] {
    background-color: $color-white;
    border: 2px solid $color-black;
    box-sizing: border-box;
    z-index: 100000;
    overflow: scroll;
    height: 100%;
    max-height: 100vh;
    @include breakpoint($landscape-up) {
      min-height: auto;
      min-width: $medium-up;
      position: absolute;
      transform: translateX(-50%);
      #{$ldirection}: 50vw;
      padding: 15px;
      height: auto;
    }
  }
  .content-block-backdrop {
    bottom: 0;
    display: none;
    overflow-y: auto;
    position: fixed;
    top: 0;
    z-index: 100000;
    #{$rdirection}: 0;
    #{$ldirection}: 0;
    background: $color-black-overlay;
  }
  .content-block-backdrop.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .no-scroll {
    overflow-y: auto !important;
  }
  /* this is added to the body when a dialog is open */
  .has-dialog {
    overflow: hidden;
  }
  .content-block-overlay__close {
    position: fixed;
    z-index: 1;
    cursor: pointer;
    background: transparent;
    padding: 0;
    border: none;
    min-width: auto;
    width: 15px;
    margin: 5px;
    top: 15px;
    #{$rdirection}: 15px;
    svg {
      display: block;
      width: 15px;
      height: 15px;
      stroke: $color-black;
      stroke-width: 50;
    }
  }
  .content-block-overlay__content {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: end;
    padding: 30px;
  }
}
.content-block {
  &.content-block--overlay {
    position: initial;
  }
}
.content-block-overlay--mini-popup {
  .content-block-backdrop {
    position: static;
  }
  [role='dialog'] {
    bottom: 0;
    position: fixed;
    top: auto;
    transform: none;
    #{$rdirection}: 0;
    #{$ldirection}: auto;
    @include breakpoint($landscape-up) {
      max-width: 30vw;
    }
  }
}
