///
/// @file global/_media-queries.scss
///
/// \brief Named media queries for easy use in nested selectors
///
/// In general these should be used in a mobile-first fashion, meaning
/// everything is coded for $small, then overridden for $medium-up and
/// overridden once more for $large-up. So you shouldn't even have to reference
/// the $small-*, $*-down or $*-only breakpoints, but they're here for now
/// just cause.
///

$small-down: max-width 767px;
$small-only: (0, 767px);
$small-up: 0;

$iphone5-only: max-width 374px;

$medium-down: max-width 768px;
$medium-only: (768px, 1023px);
$medium-up: 768px;
$tablet-only: (768px 1024px); //IPad
$medium-landscape-only: (width 768px 1024px) (orientation landscape); // iPad landscape
$medium-portrait-only: (width 768px 1024px) (orientation portrait); // iPad portrait
$ipad-pro-portrait-only: (width 834px 1194px) (orientation portrait); // iPad Pro portrait

$large-up: 1025px;
$small-medium-only: max-width 1024px;
$large-down: max-width 1220px; //UK CHECKOUT\\

$portrait-up: $medium-up;
$landscape-up: 1024px;

$large-up-large-down: (width 1025px 1158px);
