///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
/// @setup change all for new brand

// Layout
$max-width: 1280px !default;
$max-width-large: 1440px !default;
$header-height: 48px !default; // used to offset anchors, etc.
$medium-up-header-height: 82px !default;
$landscape-up-header-height: 119px !default;

$input-height: 30px;
$landscape-up-menu-top: 62px !default;

// Typography
$base-mobile-font-size: 14px !default;
$base-mobile-line-height: 1.42 !default;
$base-font-size: $base-mobile-font-size !default;
$base-line-height: $base-mobile-line-height !default;

// Colors (http://sachagreif.com/sass-color-variables/)
$color-black: #000 !default;
$color-white: #fff !default;
$color-white-2: #f9f8f6 !default;
$color-green: #090 !default;
$color-red: #f00 !default;
$color-orange-2: #ee0000 !default;
$color-orange: #eb3300 !default;
$color-light-brown: #f7f4ef !default;
$color-gray: #989898 !default;
$color-grey: $color-gray !default;
$color-light-gray: #ccc !default;
$color-light-gray-2: #f2f1ef !default;
$color-lighter-gray: #dfdfdf !default;
$color-dark-gray: #666 !default;
$color-dark-gray-2: #747474 !default;
$color-dark-gray-3: #818181 !default;
$color-whisper: #eee !default;
$color-white-smoke: #f5f5f5 !default;
$color-dark-red: #930808 !default;
$color-floral-white: #fbf9f5 !default;
$color-medium-gray: #d2d2d2 !default;
$color-average: #dc6e10 !default;
$color-good: #dccc10 !default;
$tick: '\2713';
$cross: '\2715';

$color-success-msg: #087443 !default;
$color-error-msg: #a80000 !default;
$color-alert-msg: #b54708 !default;

$color-white-overlay: rgba(255, 255, 255, 0.7) !default;
$color-black-overlay: rgba(0, 0, 0, 0.7) !default;
$color-black-transparent: rgba(0, 0, 0, 0);
$color-light-black: rgba(76, 76, 75, 0.1);

$black: $color-black !default;
$white: $color-white !default;

$color-pink: #eb3300 !default; /*should be removed!*/

$color-text: $color-black !default;
$color-link: $color-black !default;
$color-link-hover: $color-orange !default;
$color-border: #b4b4b4;
$color--success: #07bb5b !default;

// common variables
$border: $black solid 1px !default;
$border-grey: $color-gray solid 1px !default;
$border-light-gray: #ebebeb !default;

// frontend validation variables
$border-color-normal: $color-border;
$border-color-error: $color-red;
$color-valid: $color-green;
$color-error: $color-red;
$icon-background: $color-white;
$inline-error-background: $color-white;

// transparent colors (primarily for overlays)
$color-transparent--white: rgba(255, 255, 255, 0.7) !default;
$color-transparent--black: rgba(0, 0, 0, 0.5) !default;
$color-transparent--light: $color-transparent--white;
$color-transparent--dark: $color-transparent--black;

// Default transition settings
$transition-duration: 0.2s;
$transition-effect: ease-in-out;

// If you have questions about why these aren't classes
// (http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither/)
// It's because that method triggers compatability mode:
// https://github.com/h5bp/html5-boilerplate/issues/1187
$ie8: "html[data-useragent*='MSIE 8.0']";
$ie9: "html[data-useragent*='MSIE 9.0']";
$ie10: "html[data-useragent*='MSIE 10.0']";
$ie11: "html[data-useragent*='MSIE 11.0']";

// Compass variables
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;

// Search
// @todo cleanup
$mobile_search_header_height: 60px;
$pc-search-width: 551px;

// Left to right support
$text-direction: ltr !default;
$rdirection: right !default;
$ldirection: left !default;

// Fonts
$font--serif: serif !default;
$font--sans: sans-serif !default;
$font--mono: monospace !default;
$font--text: 'Inter-Regular', serif;
$font-text--small: 'Inter-Regular', serif;
$font-inter-black: 'Inter-Black', sans-serif;
$font-inter-bold: 'Inter-Bold', sans-serif;
$font-inter-extra-bold: 'Inter-ExtraBold', sans-serif;
$font-inter-extra-light: 'Inter-ExtraLight', sans-seriff;
$font-inter-light: 'Inter-Light', sans-serif;
$font-inter-medium: 'Inter-Medium', sans-serif;
$font-inter-regular: 'Inter-Regular', sans-serif;
$font-inter-semi-bold: 'Inter-SemiBold', sans-serif;
$font-inter-thin: 'Inter-Thin', sans-serif;
$font-inter-black: 'Inter-Black', sans-serif;
$font-japnesh-reimin: '黎ミン M', 'Reimin Medium';
$font-arabic-axtbassima: 'AXtBassima-Regular', serif;
$font-korean-regular: 'KoreanLatin-Regular', serif;
$font-korean-medium: 'KoreanLatin-Medium', serif;
$font-chinese-regular: 'ChineseSimplifiedLatin-Regular', serif;
$font-chinese-medium: 'ChineseSimplifiedLatin-Medium', serif;

// Cart
$cart-line: 1px solid $color-light-gray; // line used everywhere

// Checkout
$paypal-guest-payment: true;

// Sitewide Banner Blocks.
$content-block-signup-height: 70px !default;
$content-block-signup-mobile-height: 125px !default;

// Offer Banner.
$offer-banner-height: 50px !default;
$offer-banner-height--mobile: 62px !default;

$product-grid-gap: 2px !default;
